/* global rp$, document, window */
var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	/********************************************
	 * Load Javascript for the Search By Vin Module Module
	 * -----------------------------------------
	 * @section search_by_vin_js
	 ********************************************/

	(function SearchListingModule() {
		$onReady({
			rp$, window,
			label: 'search_by_vin',
			fn: function () {
				rp$('.find-vin-link').on('click', function() {
					rp$('#findVinDialog').modal({show: true});
					rp$('#findVinDialog').appendTo(rp$('body'));
				});
			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
